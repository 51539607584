import * as React from "react";
import { Notification, useLogin, useNotify } from "react-admin";
import { Paper, Typography, Button, TextField, Box, Stack, CircularProgress, InputAdornment, IconButton, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { Link as RouterLink } from "react-router-dom";
import logoImage from '../public/icon.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Login';

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  textAlign: "center",
  fontWeight: 500,
  transition: "color 0.3s",
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: 450,
  margin: 'auto',
  marginTop: theme.spacing(2),
  borderRadius: 8,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)"
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const Logo = styled('img')({
  height: 160,
  width: 'auto',
});

const FormContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const ButtonProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

const DividerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: `${theme.spacing(3)} 0`,
}));

const StyledDivider = styled(Divider)({
  flexGrow: 1,
});

const DividerText = styled(Typography)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)}`,
  color: theme.palette.text.secondary,
}));

const LoginIconStyled = styled(LoginIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.primary.main,
  margin: '0 auto 16px auto',
  display: 'block',
}));

export const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    email: "",
    password: ""
  });
  
  const login = useLogin();
  const notify = useNotify();

  const validateForm = () => {
    let tempErrors = {
      email: "",
      password: ""
    };
    let isValid = true;

    // Email validation
    if (!email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    // Password validation
    if (!password) {
      tempErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      login({ email, password })
        .catch(() => {
          notify("Invalid email or password", { type: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.secondary.main,
          padding: 3,
          boxSizing: 'border-box',
        }}
      >
        <Box sx={{ maxWidth: 450, width: '100%' }}>
          <LogoContainer>
            <Logo src={logoImage} alt="Logo" />
          </LogoContainer>
          
          <StyledPaper elevation={3}>
            <LoginIconStyled />
            <Typography variant="h4" align="center" sx={{ fontWeight: 600, mb: 1 }}>
              Welcome Back
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 4 }}>
              Log in to access your Turbo communication dashboard
            </Typography>
            
            <FormContainer>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    name="email"
                    type="email"
                    label="Email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    fullWidth
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    fullWidth
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon color="action" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  
                  <Box sx={{ textAlign: 'right' }}>
                    <StyledLink to="/forgot">
                      Forgot Password?
                    </StyledLink>
                  </Box>
                  
                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      size="large"
                      disabled={loading}
                    >
                      Log In
                    </Button>
                    {loading && <ButtonProgress size={24} />}
                  </ButtonWrapper>
                </Stack>
              </form>
            </FormContainer>
            
            <DividerContainer>
              <StyledDivider />
              <DividerText variant="body2">OR</DividerText>
              <StyledDivider />
            </DividerContainer>
            
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography variant="body2">
                Don't have an account?{" "}
                <StyledLink to="/signup">
                  Sign up
                </StyledLink>
              </Typography>
            </Box>
          </StyledPaper>
          <Notification />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
