import { ListParticipants } from "./list";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { CreateParticipants } from "./create";
import { ShowParticipant } from "./show";
import { ParticipantEdit } from "./edit";

export const participants = {
    name: "participants",
    options: {
        label: "Participant"
    },
    icon: PersonOutlineIcon,
    list: ListParticipants,
    create: CreateParticipants,
    show: ShowParticipant,
    edit: ParticipantEdit
};