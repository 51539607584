import React, { useState, useEffect } from "react";
import {
    Title,
    useGetList,
    Loading,
    Error,
    useTranslate,
    useGetIdentity,
} from "react-admin";
import {
    Grid,
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    IconButton,
    Chip,
    LinearProgress,
    useTheme,
    Tooltip,
    Badge,
} from "@mui/material";
import {
    Message as MessageIcon,
    People as PeopleIcon,
    Tag as TagIcon,
    WhatsApp as WhatsAppIcon,
    Phone as PhoneIcon,
    MonetizationOn as MoneyIcon,
    TrendingUp as TrendingUpIcon,
    MoreVert as MoreVertIcon,
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    Refresh as RefreshIcon,
    AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend } from 'recharts';

// Status chip component with appropriate colors
const StatusChip = ({ status }) => {
    let color = "default";
    let icon = null;
    
    switch (status.toLowerCase()) {
        case 'success':
            color = "success";
            icon = <CheckCircleIcon fontSize="small" />;
            break;
        case 'failed':
            color = "error";
            icon = <ErrorIcon fontSize="small" />;
            break;
        case 'pending':
        case 'submitted':
        case 'submmitted':
            color = "warning";
            icon = <AccessTimeIcon fontSize="small" />;
            break;
        default:
            color = "default";
    }
    
    return (
        <Chip 
            size="small" 
            label={status} 
            color={color} 
            icon={icon}
            sx={{ textTransform: 'capitalize' }}
        />
    );
};

// Enhanced metric card with animations and better styling
const MetricCard = ({ icon, title, value, subtitle, trend, color }) => {
    const theme = useTheme();
    const [animatedValue, setAnimatedValue] = useState(0);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimatedValue(value);
        }, 100);
        
        return () => clearTimeout(timer);
    }, [value]);
    
    return (
        <Paper 
            elevation={2} 
            sx={{ 
                p: 2, 
                height: '100%', 
                borderRadius: 2,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: theme.shadows[8],
                }
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar 
                        sx={{ 
                            bgcolor: color || theme.palette.primary.main,
                            color: '#fff',
                            mr: 1.5
                        }}
                    >
                        {icon}
                    </Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                        {title}
                    </Typography>
                </Box>
                {trend && (
                    <Chip 
                        icon={<TrendingUpIcon />} 
                        label={`+${trend}%`} 
                        size="small" 
                        color="success" 
                        variant="outlined"
                    />
                )}
            </Box>
            <Typography variant="h3" component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
                {animatedValue.toLocaleString()}
            </Typography>
            {subtitle && (
                <Typography variant="body2" color="text.secondary">
                    {subtitle}
                </Typography>
            )}
        </Paper>
    );
};

// Enhanced recent activity component
const RecentActivity = ({ resource, icon: Icon, getTitle, getSubtitle, getStatus, color }) => {
    const { data, isLoading, error, refetch } = useGetList(
        resource,
        {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'createdAt', order: 'DESC' }
        }
    );
    const theme = useTheme();

    if (isLoading) return <Box sx={{ p: 2 }}><LinearProgress /></Box>;
    if (error) return <Error error={error} />;
    if (!data || data.length === 0) return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">No recent activity</Typography>
        </Box>
    );

    return (
        <>
            <List sx={{ p: 0 }}>
                {data.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <ListItem 
                            sx={{ 
                                py: 1.5,
                                transition: 'background-color 0.2s',
                                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                            }}
                            secondaryAction={
                                getStatus && (
                                    <StatusChip status={getStatus(item)} />
                                )
                            }
                        >
                            <ListItemIcon>
                                <Avatar 
                                    sx={{ 
                                        bgcolor: color || theme.palette.primary.main,
                                        width: 36, 
                                        height: 36 
                                    }}
                                >
                                    <Icon fontSize="small" />
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" fontWeight="medium">
                                        {getTitle(item)}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="body2" color="text.secondary">
                                        {getSubtitle ? getSubtitle(item) : new Date(item.createdAt).toLocaleString()}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        {index < data.length - 1 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                ))}
            </List>
        </>
    );
};

const Dashboard = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const { identity } = useGetIdentity();
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    
    // Function to handle refresh
    const handleRefresh = () => {
        setRefreshTrigger(prev => prev + 1);
    };

    const { data: bulkData, isLoading: bulkLoading } = useGetList(
        'bulk',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'createdAt', order: 'DESC' }
        },
        { refetchKey: refreshTrigger }
    );

    const { data: participantsData, isLoading: participantsLoading } = useGetList(
        'participants',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'createdAt', order: 'DESC' }
        },
        { refetchKey: refreshTrigger }
    );

    const { data: tagsData, isLoading: tagsLoading } = useGetList(
        'tags',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'createdAt', order: 'DESC' }
        },
        { refetchKey: refreshTrigger }
    );

    if (bulkLoading || participantsLoading || tagsLoading) return <Loading />;

    const totalSMS = bulkData?.filter(b => b.method === 'SMS').length || 0;
    const totalWhatsApp = bulkData?.filter(b => b.method === 'WhatsApp').length || 0;
    const totalVoice = bulkData?.filter(b => b.method === 'Voice').length || 0;
    const totalAirtime = bulkData?.filter(b => b.method === 'Airtime').length || 0;
    
    // Success and failure metrics
    const successMessages = bulkData?.filter(b => b.status?.toLowerCase() === 'success').length || 0;
    const failedMessages = bulkData?.filter(b => b.status?.toLowerCase() === 'failed').length || 0;
    const pendingMessages = bulkData?.filter(b => 
        b.status?.toLowerCase() === 'pending' || 
        b.status?.toLowerCase() === 'submitted' || 
        b.status?.toLowerCase() === 'submmitted'
    ).length || 0;
    
    // Calculate success rate
    const totalMessages = totalSMS + totalWhatsApp + totalVoice + totalAirtime;
    const successRate = totalMessages > 0 ? Math.round((successMessages / totalMessages) * 100) : 0;
    
    // Data for pie chart
    const messageTypeData = [
        { name: 'SMS', value: totalSMS, color: theme.palette.primary.main },
        { name: 'WhatsApp', value: totalWhatsApp, color: '#25D366' },
        { name: 'Voice', value: totalVoice, color: theme.palette.secondary.main },
        { name: 'Airtime', value: totalAirtime, color: theme.palette.warning.main },
    ].filter(item => item.value > 0);
    
    // Data for status chart
    const statusData = [
        { name: 'Success', value: successMessages, color: theme.palette.success.main },
        { name: 'Failed', value: failedMessages, color: theme.palette.error.main },
        { name: 'Pending', value: pendingMessages, color: theme.palette.warning.main },
    ].filter(item => item.value > 0);

    return (
        <Box sx={{ pb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Tooltip title="Refresh Dashboard">
                    <IconButton onClick={handleRefresh} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            
            <Grid container spacing={3}>
                {/* Main Metrics Section */}
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<PeopleIcon />}
                        title="Participants"
                        value={participantsData?.length || 0}
                        subtitle="Total registered"
                        trend="5"
                        color={theme.palette.primary.main}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<MessageIcon />}
                        title="Total Messages"
                        value={totalMessages}
                        subtitle="All channels"
                        color="#1976d2"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<CheckCircleIcon />}
                        title="Success Rate"
                        value={successRate}
                        subtitle="Percentage"
                        trend={successRate > 80 ? "2" : undefined}
                        color={theme.palette.success.main}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<TagIcon />}
                        title="Active Tags"
                        value={tagsData?.length || 0}
                        subtitle="Participant categories"
                        color={theme.palette.info.main}
                    />
                </Grid>

                {/* Charts Section */}
                <Grid item xs={12} md={8}>
                    <Card sx={{ height: '100%', borderRadius: 2 }}>
                        <CardHeader 
                            title="Distribution" 
                            subheader="All communication channels"
                            action={
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        />
                        <CardContent sx={{ height: 300 }}>
                            {messageTypeData.length > 0 ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        data={messageTypeData}
                                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <RechartsTooltip formatter={(value) => [`${value} messages`, 'Count']} />
                                        <Legend />
                                        <Bar dataKey="value" name="Messages">
                                            {messageTypeData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography variant="body1" color="text.secondary">No message data available</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%', borderRadius: 2 }}>
                        <CardHeader 
                            title="Status" 
                            subheader="All communication channels"
                        />
                        <CardContent sx={{ height: 300 }}>
                            {statusData.length > 0 ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={statusData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                            label={false}
                                        >
                                            {statusData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <RechartsTooltip 
                                            formatter={(value, name) => [`${value} messages (${((value/totalMessages) * 100).toFixed(1)}%)`, name]} 
                                            contentStyle={{ borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}
                                        />
                                        <Legend 
                                            formatter={(value, entry) => (
                                                <span style={{ color: entry.color, fontWeight: 'medium' }}>{value}</span>
                                            )}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography variant="body1" color="text.secondary">No status data available</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Recent Activities Section */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: 2 }}>
                        <CardHeader 
                            title={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <MessageIcon sx={{ mr: 1 }} color="primary" />
                                    <Typography variant="h6">Recent Messages</Typography>
                                </Box>
                            }
                            action={
                                <Badge badgeContent={bulkData?.length > 5 ? bulkData.length - 5 : 0} color="primary">
                                    <IconButton size="small">
                                        <MoreVertIcon />
                                    </IconButton>
                                </Badge>
                            }
                        />
                        <Divider />
                        <RecentActivity
                            resource="bulk"
                            icon={MessageIcon}
                            getTitle={item => `${item.method} to ${item.phoneNumber || 'Multiple Recipients'}`}
                            getSubtitle={item => `${item.content?.substring(0, 30)}${item.content?.length > 30 ? '...' : ''} • ${new Date(item.createdAt).toLocaleString()}`}
                            getStatus={item => item.status}
                            color={theme.palette.primary.main}
                        />
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: 2 }}>
                        <CardHeader 
                            title={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PeopleIcon sx={{ mr: 1 }} color="primary" />
                                    <Typography variant="h6">Recent Participants</Typography>
                                </Box>
                            }
                            action={
                                <Badge badgeContent={participantsData?.length > 5 ? participantsData.length - 5 : 0} color="primary">
                                    <IconButton size="small">
                                        <MoreVertIcon />
                                    </IconButton>
                                </Badge>
                            }
                        />
                        <Divider />
                        <RecentActivity
                            resource="participants"
                            icon={PeopleIcon}
                            getTitle={item => `${item.firstName || ''} ${item.lastName || ''}`}
                            getSubtitle={item => `${item.phoneNumber} • Added ${new Date(item.createdAt).toLocaleDateString()}`}
                            color={theme.palette.info.main}
                        />
                    </Card>
                </Grid>

                {/* Additional Metrics */}
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<MessageIcon />}
                        title="SMS"
                        value={totalSMS}
                        subtitle="Total messages"
                        color={theme.palette.primary.main}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<WhatsAppIcon />}
                        title="WhatsApp"
                        value={totalWhatsApp}
                        subtitle="Total messages"
                        color="#25D366"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<PhoneIcon />}
                        title="Voice Calls"
                        value={totalVoice}
                        subtitle="Total calls"
                        color={theme.palette.secondary.main}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<MoneyIcon />}
                        title="Airtime Sent"
                        value={totalAirtime}
                        subtitle="Total transactions"
                        color={theme.palette.warning.main}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;