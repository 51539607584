import * as React from "react";
import { Notification, useNotify, useRedirect } from "react-admin";
import { Paper, Typography, Button, TextField, Box, Stack, CircularProgress, InputAdornment, IconButton, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { client } from "./providers/client";
import { useRequestState } from "./resources/hooks/request";
import { Link as RouterLink } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import logoImage from '../public/icon.png';

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  textAlign: "center",
  fontWeight: 500,
  transition: "color 0.3s",
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: 450,
  margin: 'auto',
  marginTop: theme.spacing(2),
  borderRadius: 8,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)"
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const Logo = styled('img')({
  height: 160,
  width: 'auto',
});

const FormContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const ButtonProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

const DividerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: `${theme.spacing(3)} 0`,
}));

const StyledDivider = styled(Divider)({
  flexGrow: 1,
});

const DividerText = styled(Typography)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)}`,
  color: theme.palette.text.secondary,
}));

const SignUpIconStyled = styled(HowToRegIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.primary.main,
  margin: '0 auto 16px auto',
  display: 'block',
}));

const PasswordHelperText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(0.5),
}));

export const SignUpPage = () => {
  const register = useRequestState(signUp);
  const redirect = useRedirect();
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  
  // Form validation states
  const [errors, setErrors] = React.useState({
    email: "",
    username: "",
    password: ""
  });

  const notify = useNotify();

  const validateForm = () => {
    let tempErrors = {
      email: "",
      username: "",
      password: ""
    };
    let isValid = true;

    // Email validation
    if (!email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    // Username validation
    if (!username) {
      tempErrors.username = "Username is required";
      isValid = false;
    } else if (username.length < 3) {
      tempErrors.username = "Username must be at least 3 characters";
      isValid = false;
    }

    // Password validation
    if (!password) {
      tempErrors.password = "Password is required";
      isValid = false;
    } else if (password.length < 8) {
      tempErrors.password = "Password must be at least 8 characters";
      isValid = false;
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(password)) {
      tempErrors.password = "Password must contain uppercase, lowercase and numbers";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      register
        .fetch({ email, username, password })
        .then(data => {
          notify(data.message || "Account created successfully! Please log in.", "success");
          redirect('/login');
        })
        .catch(error => {
          notify(error.response?.data?.message || error.message, "error");
        });
    }
  }
  
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.secondary.main,
          padding: 3,
          boxSizing: 'border-box',
        }}
      >
        <Box sx={{ maxWidth: 450, width: '100%' }}>
          <LogoContainer>
            <Logo src={logoImage} alt="Logo" />
          </LogoContainer>
          
          <StyledPaper elevation={3}>
            <SignUpIconStyled />
            <Typography variant="h4" align="center" sx={{ fontWeight: 600, mb: 1 }}>
              Create Account
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 4 }}>
              Join Turbo to start communicating with your team
            </Typography>
            
            <FormContainer>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    name="email"
                    type="email"
                    label="Email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    fullWidth
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    name="username"
                    label="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    fullWidth
                    variant="outlined"
                    error={!!errors.username}
                    helperText={errors.username}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box>
                    <TextField
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      fullWidth
                      variant="outlined"
                      error={!!errors.password}
                      helperText={errors.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon color="action" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <PasswordHelperText>
                      Password must be at least 8 characters and contain uppercase, lowercase and numbers
                    </PasswordHelperText>
                  </Box>
                </Stack>

                <ButtonWrapper>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={register.loading}
                  >
                    Sign Up
                  </Button>
                  {register.loading && <ButtonProgress size={24} />}
                </ButtonWrapper>

                <DividerContainer>
                  <StyledDivider />
                  <DividerText variant="body2">or</DividerText>
                  <StyledDivider />
                </DividerContainer>

                <Typography variant="body2" align="center">
                  Already have an account?{" "}
                  <StyledLink to="/login">
                    Log in
                  </StyledLink>
                </Typography>
              </form>
            </FormContainer>
          </StyledPaper>
        </Box>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};

function signUp({ email, username, password }) {
  return client
    .post("/portal/users/new", {
      email,
      username,
      password
    })
    .then(response => response.data)
    .catch(error => {
      console.error("Signup error:", error);
      throw error;
    });
}
