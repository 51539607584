import * as React from "react";
import { Notification, useNotify, useRedirect } from "react-admin";
import { Paper, Typography, Button, TextField, Box, Stack, CircularProgress, InputAdornment, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { useRequestState } from "./resources/hooks/request";
import { client } from "./providers/client";
import { Link as RouterLink } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import LockResetIcon from '@mui/icons-material/LockReset';
import logoImage from '../public/icon.png';

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  textAlign: "center",
  fontWeight: 500,
  transition: "color 0.3s",
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: 450,
  margin: 'auto',
  marginTop: theme.spacing(2),
  borderRadius: 8,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)"
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const Logo = styled('img')({
  height: 160,
  width: 'auto',
});

const FormContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const ButtonProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

const DividerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: `${theme.spacing(3)} 0`,
}));

const StyledDivider = styled(Divider)({
  flexGrow: 1,
});

const DividerText = styled(Typography)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)}`,
  color: theme.palette.text.secondary,
}));

const ResetIconStyled = styled(LockResetIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.primary.main,
  margin: '0 auto 16px auto',
  display: 'block',
}));

export const ForgotPasswordPage = () => {
  const emailReset = useRequestState(resetPassword);
  const redirect = useRedirect();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const notify = useNotify();
  
  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      return false;
    }
    setEmailError("");
    return true;
  };
  
  function handleSubmit(event) {
    event.preventDefault();
    if (validateEmail()) {
      setIsSubmitted(true);
      emailReset
        .fetch(email)
        .then(data => {
          notify(data.message || "Password reset instructions sent to your email", "success");
          redirect('/login');
        })
        .catch(error => {
          setIsSubmitted(false);
          notify(error.response?.data?.message || error.message, "error");
        });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.secondary.main,
          padding: 3,
          boxSizing: 'border-box',
        }}
      >
        <Box sx={{ maxWidth: 450, width: '100%' }}>
          <LogoContainer>
            <Logo src={logoImage} alt="Logo" />
          </LogoContainer>
          
          <StyledPaper elevation={3}>
            <ResetIconStyled />
            <Typography variant="h4" align="center" sx={{ fontWeight: 600, mb: 1 }}>
              Forgot Password
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 4 }}>
              Enter your email address and we'll send you instructions to reset your password
            </Typography>
            
            {isSubmitted ? (
              <Box textAlign="center" my={4}>
                <CircularProgress size={60} />
                <Typography variant="body1" sx={{ mt: 3 }}>
                  Sending reset instructions...
                </Typography>
              </Box>
            ) : (
              <FormContainer>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      type="email"
                      autoFocus
                      name="email"
                      label="Email address"
                      value={email}
                      fullWidth
                      variant="outlined"
                      error={!!emailError}
                      helperText={emailError}
                      onChange={e => setEmail(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon color="action" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      disabled={emailReset.loading}
                    >
                      Reset Password
                    </Button>
                    {emailReset.loading && <ButtonProgress size={24} />}
                  </ButtonWrapper>

                  <DividerContainer>
                    <StyledDivider />
                    <DividerText variant="body2">or</DividerText>
                    <StyledDivider />
                  </DividerContainer>

                  <Typography variant="body2" align="center">
                    Remember your password?{" "}
                    <StyledLink to="/login">
                      Log in
                    </StyledLink>
                  </Typography>
                </form>
              </FormContainer>
            )}
          </StyledPaper>
        </Box>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};

function resetPassword(email) {
  return client
    .post("auth/reset-password", {
      email
    })
    .then(response => response.data)
    .catch(error => {
      console.error("Password reset error:", error);
      throw error;
    });
}
