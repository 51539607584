import {
    Edit,
    SimpleForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    DateInput,
    Toolbar,
    SaveButton,
    useRecordContext,
    useNotify,
    useRedirect,
    Button
} from 'react-admin';
import { Box } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';

const EditToolbar = props => {
    const record = useRecordContext();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleApplyToAll = () => {
        // TODO: Implement bulk tag update for all participants
        notify('Tags will be applied to all participants', { type: 'info' });
    };

    return (
        <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton />
            <Button
                label="Apply Tags to All"
                onClick={handleApplyToAll}
                sx={{ marginLeft: 2 }}
            >
                <LabelIcon />
            </Button>
        </Toolbar>
    );
};

export const ParticipantEdit = () => (
    <Edit>
        <SimpleForm toolbar={<EditToolbar />}>
            <Box display="flex" flexDirection="column" gap={2}>
                <TextInput source="firstName" label="First Name" />
                <TextInput source="lastName" label="Last Name" />
                <TextInput source="phoneNumber" label="Phone Number" />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'active', name: 'Active' },
                        { id: 'inactive', name: 'Inactive' },
                    ]}
                />
                <DateInput source="dateOfBirth" label="Date of Birth" />
                <ArrayInput source="tag" label="Tags">
                    <SimpleFormIterator inline>
                        <TextInput source="" label="Tag" />
                    </SimpleFormIterator>
                </ArrayInput>
            </Box>
        </SimpleForm>
    </Edit>
);
