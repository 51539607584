import React, { useEffect, useState } from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, SelectInput, FormDataConsumer } from "react-admin";
import { backendUrl } from "../../../config";
import { Box, Typography, Divider, Alert } from '@mui/material';

const fetchUserProfile = async (id) => {
  try {
    const response = await fetch(`${backendUrl}/portal/userprofile/${id}`);
    const data = await response.json();
    return { data };
  } catch (error) {
    return { error };
  }
};

const UserProfile = (props) => {
  const [userProfile, setUserProfile] = useState(null);
  const userID = localStorage.getItem("user_id");

  useEffect(() => {
    if (userID) {
      async function fetchData() {
        const { data, error } = await fetchUserProfile(userID);
        if (data) {
          setUserProfile(data);
        } else if (error) {
          console.error("Error fetching user profile:", error.message);
        }
      }
      fetchData();
    } else {
      console.log("UserID not found in local storage");
    }
  }, [userID]);

  if (!userProfile) return <div>Loading...</div>;

  return (
    <Edit title="Settings" {...props} id={userID}>
      <SimpleForm initialValues={userProfile}>
        <TextInput source="username" disabled fullWidth />
        <TextInput source="name" fullWidth />
        <TextInput source="email" fullWidth />
        <TextInput source="phone" fullWidth />
        <TextInput source="country" fullWidth />
        
        {/* Custom API Provider Section - Only shown if admin has enabled this feature */}
        <FormDataConsumer>
          {({ formData }) => {
            // Check if the admin has allowed this user to use custom API
            const allowCustomApi = formData?.customApiProvider?.allowUserCustomApi;
            
            if (allowCustomApi) {
              return (
                <Box mt={4}>
                  <Typography variant="subtitle1" fontWeight="bold">Custom API Provider</Typography>
                  <Typography variant="body2" color="textSecondary" mb={1}>
                    Use your own API credentials instead of the system default
                  </Typography>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    This feature costs 10,000 UGX per month, automatically deducted from your wallet.
                  </Alert>
                  <Divider sx={{ mb: 2 }} />
                  
                  <Box display="flex" flexDirection="column" gap={2}>
                    <BooleanInput
                      source="customApiProvider.enabled"
                      label="Enable Custom API Provider"
                      helperText="Turn on to use your own API credentials"
                    />
                    
                    <FormDataConsumer>
                      {({ formData }) => {
                        // Only show API configuration if enabled
                        if (formData?.customApiProvider?.enabled) {
                          return (
                            <>
                              <SelectInput
                                source="customApiProvider.provider"
                                label="API Provider"
                                choices={[
                                  { id: 'AfricasTalking', name: 'Africa\'s Talking' },
                                  { id: 'Other', name: 'Other' }
                                ]}
                              />
                              <TextInput 
                                source="customApiProvider.apiKey" 
                                label="API Key" 
                                fullWidth 
                              />
                              <TextInput 
                                source="customApiProvider.username" 
                                label="API Username" 
                                fullWidth 
                              />
                              <TextInput 
                                source="customApiProvider.fromSender" 
                                label="Sender ID (optional)" 
                                fullWidth 
                                helperText="Custom sender name/number for outgoing messages"
                              />
                            </>
                          );
                        }
                        return null;
                      }}
                    </FormDataConsumer>
                  </Box>
                </Box>
              );
            }
            return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export { UserProfile };
