import React from "react";
import { useRefresh, useNotify } from "react-admin";
import UploadBulkIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { useRequestState } from "../hooks/request";
import { client } from "../../providers/client";
import { Button, Stack } from '@mui/material';

export const UploadBulk = ({ 
  customUploadFunction, 
  onUploadStart, 
  onUploadSuccess, 
  onUploadError, 
  isUploading 
}) => {
  // Don't use useRequestState for customUploadFunction to avoid wrapping it
  const defaultUploadBulk = useRequestState(uploadExcelSheet);
  const notify = useNotify();
  const refresh = useRefresh();
  
  // Log that the component was initialized with or without a custom upload function
  console.log('UploadBulk initialized with customUploadFunction:', !!customUploadFunction);

  function onCapture() {
    const uploadInput = document.createElement("input");
    uploadInput.type = "file";
    uploadInput.accept = ".xlsx,.xls,.csv";
    uploadInput.multiple = false;
    uploadInput.onchange = handleUpload;
    uploadInput.click();
  }

  function handleUpload(e) {
    if (!e.target.files || e.target.files.length === 0) return;
    
    const file = e.target.files[0]; // Only take the first file
    console.log('Selected file:', file.name); // Debug log
    
    const formData = new FormData();
    formData.append('file', file);
    console.log('FormData after adding file:', formData.get('file') ? 'File added successfully' : 'File not added'); // Debug log
    
    // Call onUploadStart if provided
    if (onUploadStart) {
      console.log('Calling onUploadStart'); // Debug log
      onUploadStart(file);
    }

    // Log the customUploadFunction for debugging
    console.log('Using customUploadFunction:', !!customUploadFunction ? 'Custom function provided' : 'Using default uploadExcelSheet'); // Debug log

    // Use the appropriate upload function
    if (customUploadFunction) {
      // If we have a custom upload function, use it directly
      console.log('Using custom upload function directly');
      return customUploadFunction(formData)
        .then((result) => {
          console.log('Custom upload successful, result:', result); // Debug log
          if (onUploadSuccess) {
            console.log('Calling onUploadSuccess'); // Debug log
            onUploadSuccess(result);
          } else {
            notify('Bulk upload successful!');
            refresh();
          }
        })
        .catch(error => {
          console.error('Custom upload error details:', error); // Debug log
          if (onUploadError) {
            console.log('Calling onUploadError'); // Debug log
            onUploadError(error);
          } else {
            notify(error?.response?.data?.message || 'Upload failed. Please check your file format.');
          }
        });
    } else {
      // Use the default upload function through useRequestState
      console.log('Using default upload function through useRequestState');
      return defaultUploadBulk
        .fetch(formData)
        .then((result) => {
          console.log('Default upload successful, result:', result); // Debug log
          if (onUploadSuccess) {
            console.log('Calling onUploadSuccess'); // Debug log
            onUploadSuccess(result);
          } else {
            notify('Bulk upload successful!');
            refresh();
          }
        })
        .catch(error => {
          console.error('Default upload error details:', error); // Debug log
          if (onUploadError) {
            console.log('Calling onUploadError'); // Debug log
            onUploadError(error);
          } else {
            notify(error?.response?.data?.message || 'Upload failed. Please check your file format.');
          }
        });
    }
  }

  function downloadTemplate() {
    const csvContent = `Phone,Tag,FirstName,LastName,DateOfBirth
+256700000000,VIP,John,Doe,1990-01-01
+256711111111,Regular,Jane,Smith,1992-05-15`;
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'bulk-upload-template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onCapture}
        startIcon={isUploading ? null : <UploadBulkIcon />}
        disabled={isUploading}
      >
        {isUploading ? 'Uploading...' : 'Upload Bulk'}
      </Button>
      {/* <Button
        variant="outlined"
        color="primary"
        onClick={downloadTemplate}
        startIcon={<DownloadIcon />}
        disabled={isUploading}
      >
        Download Template
      </Button> */}
    </Stack>
  );
};

async function uploadExcelSheet(content) {
  return await client.put("/portal/bulk/upload", content, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
