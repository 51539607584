import {
    SimpleForm,
    Create,
    TextInput,
    required,
    useNotify,
    useRedirect,
    FormDataConsumer,
    Toolbar,
    SaveButton
} from "react-admin";
import { useState } from "react";
import { 
    Box, 
    Stepper, 
    Step, 
    StepLabel, 
    Button, 
    Typography, 
    Paper, 
    Divider,
    CircularProgress,
    TextField
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { UploadBulk } from "../components/upload-bulk";
import { styled } from "@mui/material/styles";
import { client } from "../../providers/client";

// Styled components
const StepContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
}));

const StepContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const StepperButtons = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
}));

const StepIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    "& svg": {
        fontSize: 48,
        color: theme.palette.primary.main
    }
}));

// Steps for the upload process
const steps = [
    'Upload Participants',
    'Review & Confirm'
];

// Custom toolbar that doesn't show the save button
const CustomToolbar = () => (
    <Toolbar>
        {/* Empty toolbar */}
    </Toolbar>
);

export const CreateParticipants = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    // Tag value is now handled directly in the upload component with a default value
    const [tagValue, setTagValue] = useState('default');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const notify = useNotify();
    const redirectTo = useRedirect();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Tag change handler removed as the step was removed

    const handleUploadSuccess = () => {
        setUploadComplete(true);
        setIsUploading(false);
    };

    const handleUploadStart = () => {
        setIsUploading(true);
    };

    const handleUploadError = (error) => {
        setIsUploading(false);
        notify(error || 'Upload failed', { type: 'error' });
    };

    const handleFinish = () => {
        notify('Participants created successfully', { type: 'success' });
        redirectTo('list', 'participants');
    };

    // Content for each step
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepContent>
                        <StepIcon>
                            <CloudUploadIcon />
                        </StepIcon>
                        <Typography variant="h6" gutterBottom>
                            Upload your participants data
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                            Upload a CSV or Excel file containing your participants' information.
                            Make sure to follow the template format.
                        </Typography>
                        <Box mt={3} mb={2}>
                            <UploadBulkWrapper 
                                tag={tagValue} 
                                onUploadStart={handleUploadStart}
                                onUploadSuccess={handleUploadSuccess}
                                onUploadError={handleUploadError}
                                isUploading={isUploading}
                                uploadComplete={uploadComplete}
                            />
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2" color="textSecondary">
                            Not sure about the format? Download our template below:
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            onClick={downloadTemplate}
                            sx={{ mt: 1 }}
                        >
                            Download Template
                        </Button>
                    </StepContent>
                );
            case 1:
                return (
                    <StepContent>
                        <StepIcon>
                            <CheckCircleIcon />
                        </StepIcon>
                        <Typography variant="h6" gutterBottom>
                            Review and confirm
                        </Typography>
                        {/* Tag information removed */}
                        <Typography variant="body1" paragraph>
                            <strong>Upload Status:</strong> {uploadComplete ? 'Complete' : 'Pending'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                            Please review the information above. If everything looks correct, click Finish to complete the process.
                        </Typography>
                    </StepContent>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Create {...props} title="Add Participants">
            <SimpleForm toolbar={<CustomToolbar />} redirect="false">
                <StepContainer>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    
                    {getStepContent(activeStep)}
                    
                    <StepperButtons>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Box>
                            {activeStep === steps.length - 1 ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFinish}
                                    disabled={!uploadComplete}
                                >
                                    Finish
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={(activeStep === 1 && !uploadComplete)}
                                >
                                    Next
                                </Button>
                            )}
                        </Box>
                    </StepperButtons>
                </StepContainer>
            </SimpleForm>
        </Create>
    );
};

// Wrapper for the UploadBulk component with additional UI elements
const UploadBulkWrapper = ({ tag, onUploadStart, onUploadSuccess, onUploadError, isUploading, uploadComplete }) => {
    console.log('UploadBulkWrapper received tag:', tag); // Debug log
    
    // Create a custom upload function that will be passed to the UploadBulk component
    const customUploadFunction = async (formData) => {
        try {
            // Get the tag name
            const tagName = tag || 'default';
            console.log('Using tag name for upload:', tagName);
            
            // Simply add the tag to the existing formData
            // Don't create a new FormData object as this can cause issues
            formData.append('tag', tagName);
            formData.append('applyTagToAll', 'true');
            
            console.log('FormData has tag:', formData.get('tag'));
            console.log('FormData has applyTagToAll:', formData.get('applyTagToAll'));
            
            // Log all keys in formData for debugging
            const formDataEntries = [];
            for (let pair of formData.entries()) {
                formDataEntries.push(pair[0] + ': ' + (pair[0] === 'file' ? 'File object' : pair[1]));
            }
            console.log('FormData entries:', formDataEntries);
            
            // Use a simpler approach - just use the original formData
            console.log('Uploading file with tag:', tagName);
            const response = await client.put('/portal/bulk/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            console.log('Upload response:', response);
            return response;
        } catch (error) {
            console.error('Upload error:', error);
            throw error;
        }
    };

    const handleUploadStart = (file) => {
        console.log('Uploading file:', file.name);
        onUploadStart();
    };

    const handleUploadSuccess = (result) => {
        console.log('Upload successful:', result);
        onUploadSuccess(result);
    };

    const handleUploadError = (error) => {
        console.error('Upload error details:', error);
        onUploadError(error.message || 'Failed to upload participants');
    };

    return (
        <Box>
            {uploadComplete ? (
                <Box display="flex" alignItems="center">
                    <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                    <Typography variant="body1" color="success.main">
                        Upload completed successfully!
                    </Typography>
                </Box>
            ) : (
                <UploadBulk 
                    onUploadStart={handleUploadStart}
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                    isUploading={isUploading}
                    customUploadFunction={customUploadFunction}
                />
            )}
        </Box>
    );
};

// Function to download a template CSV file
const downloadTemplate = () => {
    const csvContent = `Phone,Tag,FirstName,LastName,DateOfBirth
+256700000000,VIP,John,Doe,1990-01-01
+256711111111,Regular,Jane,Smith,1992-05-15`;
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'bulk-upload-template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
};
