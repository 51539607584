import { 
    Show, 
    SimpleShowLayout, 
    TextField, 
    DateField, 
    ReferenceField, 
    NumberField,
    useRecordContext,
    Labeled
} from "react-admin";
import { 
    Typography, 
    Chip, 
    Box, 
    Card, 
    CardContent, 
    Divider 
} from "@mui/material";

const StatusField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'submitted':
            case 'submmitted':
                return 'default';
            case 'success':
                return 'success';
            case 'pending':
                return 'warning';
            case 'failed':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <Chip
            label={record.status}
            color={getStatusColor(record.status)}
            variant="outlined"
            size="small"
            sx={{
                fontWeight: 'medium',
                textTransform: 'capitalize'
            }}
        />
    );
};

const MessageContent = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                    {record.content}
                </Typography>
            </CardContent>
        </Card>
    );
};

export const ShowBulk = (props) => (
    <Show 
        {...props} 
        title="Message Details"
        sx={{
            '& .RaShow-main': {
                boxShadow: 1,
                borderRadius: 2,
                overflow: 'hidden',
            },
        }}
    >
        <SimpleShowLayout>
            <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="row" gap={4}>
                    <Box flex={1}>
                        <Labeled label="Phone Number">
                            <TextField source="phoneNumber" />
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Method">
                            <TextField source="method" />
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Status">
                            <StatusField source="status" />
                        </Labeled>
                    </Box>
                </Box>

                <Divider />

                <Box display="flex" flexDirection="row" gap={4}>
                    <Box flex={1}>
                        <Labeled label="Sent At">
                            <DateField 
                                source="createdAt" 
                                showTime 
                                options={{
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                }}
                            />
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Cost">
                            <NumberField
                                source="amount"
                                options={{
                                    style: 'currency',
                                    currency: 'UGX',
                                    minimumFractionDigits: 2
                                }}
                            />
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="From">
                            <TextField source="from" />
                        </Labeled>
                    </Box>
                </Box>

                <Divider />

                <Box>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Recipient Details
                    </Typography>
                    <ReferenceField
                        source="participantId"
                        reference="participants"
                        link="show"
                    >
                        <Box display="flex" flexDirection="column" gap={1}>
                            <Box display="flex" gap={1}>
                                <TextField source="firstName" />
                                <TextField source="lastName" />
                            </Box>
                            <TextField source="phoneNumber" />
                            <TextField source="email" />
                        </Box>
                    </ReferenceField>
                </Box>

                <Divider />

                <Box>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Message Content
                    </Typography>
                    <MessageContent />
                </Box>
            </Box>
        </SimpleShowLayout>
    </Show>
);