import React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput,
    SelectInput,
    NumberInput,
    usePermissions,
    FormDataConsumer,
} from "react-admin";
import { Box, Divider, Typography } from '@mui/material';
import {
    roles,
    customer,
    admin,
} from "../roles";

export const CreateUser = props => {
    return (
        <Create {...props}>
            <Form {...props} />
        </Create>
    );
};

export const EditUser = props => {
    return (
        <Edit title="Edit User" {...props}>
            <Form {...props} />
        </Edit>
    );
};

export const Form = ({ permissions, ...props }) => {
    const { permissions: userPermissions } = usePermissions();
    // Check if the user has admin role by checking the role property
    const isAdmin = userPermissions && userPermissions.role === admin;

    return (
        <SimpleForm {...props} redirect={redirect}>
            <TextInput source="username" />
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <PasswordInput source="password" />
            <SelectInput
                source="role"
                choices={
                    props.permissions === customer
                        ? roles.filter(role => [customer].includes(role.id))
                        : roles
                }
            />
            
            {isAdmin && (
                <FormDataConsumer>
                    {({ formData }) => (
                        <>
                            <Box mt={2}>
                                <Typography variant="subtitle1" fontWeight="bold">Markup Fees</Typography>
                                <Typography variant="body2" color="textSecondary" mb={2}>
                                    Additional fees added to service costs
                                </Typography>
                                <Divider sx={{ mb: 2 }} />
                                
                                <Box display="flex" flexDirection="column" gap={2}>
                                    <NumberInput 
                                        source="makeupFee" 
                                        label="Legacy Markup Fee (All Services)" 
                                        helperText="For backward compatibility"
                                        min={0}
                                        defaultValue={5}
                                    />
                                    
                                    <NumberInput 
                                        source="markupFees.sms" 
                                        label="SMS Markup Fee" 
                                        helperText="Additional fee added to SMS cost"
                                        min={0}
                                        defaultValue={5}
                                    />
                                    
                                    <NumberInput 
                                        source="markupFees.voice" 
                                        label="Voice Call Markup Fee" 
                                        helperText="Additional fee added to voice call cost"
                                        min={0}
                                        defaultValue={10}
                                    />
                                    
                                    <NumberInput 
                                        source="markupFees.airtimePercent" 
                                        label="Airtime Markup Percentage" 
                                        helperText="Percentage markup added to airtime cost (e.g., 3 means 3%)"
                                        min={0}
                                        defaultValue={3}
                                        step={0.1}
                                    />
                                </Box>
                            </Box>
                            
                            <Box mt={4}>
                                <Typography variant="subtitle1" fontWeight="bold">Custom API Provider Settings</Typography>
                                <Typography variant="body2" color="textSecondary" mb={2}>
                                    Allow users to use their own API credentials (10,000 UGX monthly fee)
                                </Typography>
                                <Divider sx={{ mb: 2 }} />
                                
                                <Box display="flex" flexDirection="column" gap={2}>
                                    <SelectInput
                                        source="customApiProvider.allowUserCustomApi"
                                        label="Allow Custom API Provider"
                                        helperText="Enable this to allow the user to use their own API credentials"
                                        choices={[
                                            { id: true, name: 'Enabled' },
                                            { id: false, name: 'Disabled' },
                                        ]}
                                        defaultValue={false}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </FormDataConsumer>
            )}
        </SimpleForm>
    );
};

const redirect = (basePath, _id) => {
    if (/profile/.test(window.location.search)) {
        return "/";
    }

    return basePath;
};